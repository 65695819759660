import { Image } from 'src/components/Display/Image'
import { Link } from 'src/components/Link'
import { SignInForm } from 'src/components/SignInForm'
import { useSignupUrl } from 'src/hooks/useSignupUrl'
import { CredentialResponse, GoogleSignInButton } from './GoogleSignInButton'
import { useCallback, useState } from 'react'
import { AppleSignInButtonButton } from './AppleSignInButton'
import { startSessionViaApple, startSessionViaGoogle } from 'src/models/session'
import { getGeoLocationFromHeaders } from 'src/utils/getGeoLocationFromHeaders'
import { setGeoLocation, setUser } from 'src/hooks/useUser'

const googleClientId = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID ?? ''
const appleClientId = process.env.NEXT_PUBLIC_APPLE_CLIENT_ID ?? ''
const appleRedirectUri = process.env.NEXT_PUBLIC_APPLE_REDIRECT_URI ?? ''

export const SignIn = () => {
  const signUpUrl = useSignupUrl()
  const [errorMessage, setErrorMessage] = useState('')

  const googleSignInSuccessHandler = useCallback(
    async (res: CredentialResponse) => {
      if (res.credential) {
        try {
          setErrorMessage('')
          const response = await startSessionViaGoogle({ id_token: res.credential })

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const geoLocation = getGeoLocationFromHeaders(response)
          setUser(response.data)
          setGeoLocation(geoLocation)
        } catch (error) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setErrorMessage(error?.response?.data?.error || error?.message)
        }
      }
    },
    [setErrorMessage],
  )

  const appleSignInSuccessHandler = useCallback(async (data: AppleSignInAPI.SignInResponseI) => {
    if (data) {
      try {
        setErrorMessage('')
        const response = await startSessionViaApple({
          id_token: data.authorization.id_token,
          access_token: data.authorization.code,
          first_name: data.user?.name?.firstName,
          last_name: data.user?.name?.lastName,
        })

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const geoLocation = getGeoLocationFromHeaders(response)
        setUser(response.data)
        setGeoLocation(geoLocation)
      } catch (error) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setErrorMessage(error?.response?.data?.error || error?.message)
      }
    }
  }, [])

  const handleLoginClick = useCallback(() => setErrorMessage(''), [setErrorMessage])

  return (
    <div className="flex h-full justify-center pt-6">
      <div
        className="flex-column margin-auto flex w-full items-center gap-2.5 bg-white p-[1rem] md:w-[504px]
          md:rounded-[20px] md:border md:border-neutral-400 md:p-10 md:shadow-[0px_8px_32px_0px_#0000000D]"
      >
        <Link action='Returned to Marketing Page' to='/'>
          <Image src='/obe-logo-color.png' className="h-[30px] w-[70px]" alt='logo' />
        </Link>
        <div className="my-3 text-2xl font-medium leading-9 tracking-[0.005em]">Welcome back!</div>
        <SignInForm onLoginClick={handleLoginClick} />
        {errorMessage && <div className="py-3 font-light text-feedback-200">{errorMessage}</div>}
        <div className="mt-2">
          <span className="font-light">Don’t have an account? </span>
          <Link synthetic={false} to={signUpUrl} className="!text-neutral-700 underline">
            Click here
          </Link>
        </div>
        <div
          className="my-4 flex w-full items-center text-sm font-light text-neutral-700 before:mr-4 before:h-px
            before:flex-1 before:bg-neutral-500 after:ml-4 after:h-px after:flex-1 after:bg-neutral-500"
        >
          OR
        </div>
        <GoogleSignInButton clientId={googleClientId} onSuccess={googleSignInSuccessHandler} />
        <AppleSignInButtonButton
          clientId={appleClientId}
          redirectUri={appleRedirectUri}
          onSuccess={appleSignInSuccessHandler}
          className="mt-2"
        />
      </div>
    </div>
  )
}
