'use client'

import { useCallback, useEffect, useRef, useState } from 'react'
import Script from 'next/script'
import { SignInButton } from './SignInButton'

export interface CredentialResponse {
  credential?: string
}

interface GoogleSignInButtonProps {
  clientId: string
  onSuccess?: (credentialResponse: CredentialResponse) => void
  onError?: () => void
  type?: 'standard' | 'icon'
  theme?: 'outline' | 'filled_blue' | 'filled_black'
  size?: 'large' | 'medium' | 'small'
  text?: 'signin_with' | 'signup_with' | 'continue_with' | 'signin'
  shape?: 'rectangular' | 'pill' | 'circle' | 'square'
  logoAlignment?: 'left' | 'center'
  width?: string | number
  locale?: string
  className?: string
}

export const GoogleSignInButton = ({
  clientId,
  onSuccess,
  onError,
  type = 'standard',
  theme = 'outline',
  size = 'large',
  text = 'continue_with',
  shape = 'pill',
  logoAlignment = 'center',
  width = '400',
  locale = 'en-US',
  className,
}: GoogleSignInButtonProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [scriptLoaded, setScriptLoaded] = useState(false)

  const handleScriptLoded = useCallback(() => setScriptLoaded(true), [setScriptLoaded])

  const handleClick = useCallback(() => {
    if (containerRef.current) {
      const googleButton = containerRef.current.querySelector('div[tabindex="0"]')

      if (googleButton) {
        ;(googleButton as HTMLElement).click()
      }
    }
  }, [containerRef])

  useEffect(() => {
    if (!scriptLoaded || !containerRef.current) return

    window?.google?.accounts?.id?.initialize({
      client_id: clientId,
      callback: (credentialResponse: CredentialResponse) => {
        if (!credentialResponse?.credential && onError) {
          return onError()
        }

        if (onSuccess) {
          onSuccess({
            credential: credentialResponse.credential,
          })
        }
      },
    })

    window?.google?.accounts?.id?.renderButton(containerRef.current, {
      type,
      theme,
      size,
      text,
      shape,
      logo_alignment: logoAlignment,
      width,
      locale,
    })
  }, [
    clientId,
    scriptLoaded,
    onSuccess,
    onError,
    type,
    theme,
    size,
    text,
    shape,
    logoAlignment,
    width,
    locale,
  ])

  return (
    <>
      <Script
        strategy='lazyOnload'
        src='https://accounts.google.com/gsi/client'
        onLoad={handleScriptLoded}
      />
      <div ref={containerRef} className="hidden" />
      <SignInButton logo='google' onClick={handleClick} className={className}>
        Continue with Google
      </SignInButton>
    </>
  )
}
