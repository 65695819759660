import type { MouseEventHandler, ReactNode } from 'react'
import clsx from 'clsx'
import Image from 'next/legacy/image'
import appleLogo from 'public/apple-logo.svg'
import googleLogo from 'public/google-logo.svg'

interface SignInButtonProps {
  logo?: 'apple' | 'google'
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined
  className?: string
  children: ReactNode
}

export const SignInButton = ({
  onClick,
  logo = 'apple',
  className,
  children,
}: SignInButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={clsx(
        `flex items-center justify-center w-full border-2 border-black rounded-full h-[50px] outline-none
        focus:outline-none`,
        className
      )}
    >
      <Image src={logo === 'google' ? googleLogo : appleLogo} alt={logo} unoptimized />
      <span className="pl-1 leading-[1.125rem]">{children}</span>
    </button>
  )
}
