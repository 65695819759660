'use client'

import { useCallback, useEffect, useState } from 'react'
import Script from 'next/script'
import { SignInButton } from './SignInButton'

export interface CredentialResponse {
  credential?: string
}

interface AppleSignInButtonButtonProps {
  clientId: string
  redirectUri: string
  onSuccess?: (data: AppleSignInAPI.SignInResponseI) => void
  onError?: (error: Error) => void
  className?: string
}

export const AppleSignInButtonButton = ({
  clientId,
  redirectUri,
  onSuccess,
  onError,
  className,
}: AppleSignInButtonButtonProps) => {
  const [scriptLoaded, setScriptLoaded] = useState(false)

  const handleScriptLoded = useCallback(() => setScriptLoaded(true), [setScriptLoaded])

  const handleClick = useCallback(async () => {
    try {
      const data = await AppleID.auth.signIn()

      if (onSuccess && data) {
        onSuccess(data)
      }
    } catch (err) {
      if (onError) {
        onError(err as Error)
      }
    }
  }, [onSuccess, onError])

  useEffect(() => {
    if (!scriptLoaded) return

    AppleID.auth.init({
      clientId,
      scope: 'email name',
      redirectURI: redirectUri,
      state: 'AppleSignInAuthRequest',
      usePopup: true,
    })
  }, [clientId, redirectUri, scriptLoaded, onSuccess, onError])

  return (
    <>
      <Script
        strategy='lazyOnload'
        src='https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js'
        onLoad={handleScriptLoded}
      />
      <SignInButton logo='apple' className={className} onClick={handleClick}>
        Continue with Apple
      </SignInButton>
    </>
  )
}
