import { client } from 'src/api'
import { UserType } from 'src/types/User'
import type { Friend, FriendInvitation } from 'src/types/friends'

export const searchRelatedProfiles = async (searchString: string, signal?: AbortSignal) =>
  await client.get<{
    currentFriends: Friend[]
    liveIncomingInvitations: FriendInvitation[]
  }>(`/api/v4/profiles/related?search_str=${searchString}`, { signal })

export const searchUnrelatedProfiles = async (
  page: number,
  perPage: number,
  searchString: string,
  signal?: AbortSignal,
) => {
  return await client.get<{
    currentFriends: Friend[]
    liveIncomingInvitations: FriendInvitation[]
  }>(`/api/v4/profiles/unrelated?search_str=${searchString}`, {
    params: {
      page: page,
      per_page: perPage,
    },
    signal,
  })
}

interface CreateProfileData {
  id: string
  user: {
    email?: string
    facebookUsername?: string
    firstName?: string
    lastName?: string
    birthday?: string
    fitnessLevel?: string
    instagramHandle?: string
    username?: string
    tagline?: string
    address_attributes?: {
      city?: string
      state?: string
      province?: string
      country?: string
      postalCode?: string
    }
  }
  searchable: boolean
  showRecStars?: boolean
}

export const createProfile = async (data: CreateProfileData) =>
  await client.post<UserType>('/api/v4/profiles', data)

export const updateProfile = async (userId: string, data: CreateProfileData) =>
  await client.put<UserType>(`/api/v4/profiles/${userId}`, data)

export const updateSMSPreferences = async (data: { code: string; id: string; phone: string }) =>
  await client.put<UserType>('/api/v4/profiles/sms_preferences', data)

export const startSMSVerification = async (data: { id: string | undefined; phone: string }) =>
  await client.post<void>('/api/v4/profiles/start_sms_verification', data)

export const getUsernameAvailability = async (username: string, signal?: AbortSignal) =>
  await client.get<{ available: boolean }>(
    `/api/v4/profiles/username_availability?username=${username}`,
    { signal },
  )

export const generateUsername = (id: string) =>
  client.post<UserType>('/api/v4/profiles/create_username', { id })
