'use client'

import { DispatchPageView } from 'src/components/App/DispatchPageView'
import { IsNotGuest } from 'src/components/App/RedirectIf'
import { SignUpContextProvider } from 'src/hooks/useSignUpContext/SignUpContextProvider'
import { CouponProvider } from 'src/providers/CouponProvider/CouponProvider'
import { storage } from 'src/storage'
import { SignIn } from 'src/views/SignIn/SignIn'

const SignInView = () => {
  const redirect = storage.session.getItem('returnUrl') || undefined

  return (
    <IsNotGuest redirect={redirect} paywall>
      <DispatchPageView>
        <SignUpContextProvider>
          <CouponProvider>
            <SignIn />
          </CouponProvider>
        </SignUpContextProvider>
      </DispatchPageView>
    </IsNotGuest>
  )
}

export default SignInView
