import { Form } from 'src/components/Display/Form'
import { getGeoLocationFromHeaders } from 'src/utils/getGeoLocationFromHeaders'
import { Input } from 'src/components/Input'
import { Link } from 'src/components/Link'
import { startSession } from 'src/models/session'
import { throwFormError, useForm } from 'src/hooks/useForm'
import { updateConditionalDisplay } from 'src/models/user'
import { setGeoLocation, setUser } from 'src/hooks/useUser'
import { useSWRConfig } from 'swr'

export const SignInForm = ({ onLoginClick }) => {
  const { cache, mutate } = useSWRConfig()
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')

  const { errors, isBusy, isError, isSuccess, message, submit } = useForm({
    onSubmit: async () => {
      if (onLoginClick) {
        onLoginClick()
      }
      if (email === '') {
        throwFormError('email', 'can‘t be blank')
      }
      if (password === '') {
        throwFormError('password', 'can‘t be blank')
      }

      return await startSession({ email, password })
    },
    onComplete: async ({ response, isSuccess }) => {
      if (isSuccess) {
        window._cacheKey = (window._cacheKey || 0) + 1
        obe.storage_keys.swr_me_key = window._cacheKey

        const geoLocation = getGeoLocationFromHeaders(response)
        const { subscription, account, id } = response.data

        switch (subscription?.plan) {
          // API call for existing yearly or quarterly members so they don't see gift card messaging
          case 'standard_quarterly':
            if (account?.conditionalDisplayAttributes?.quarterlySubUpgradeNotification !== false) {
              const { data } = await updateConditionalDisplay(id, {
                quarterlySubUpgradeNotification: false,
              })
              setUser({
                ...response.data,
                account: data.account,
              })
            } else {
              setUser(response.data)
            }
            setGeoLocation(geoLocation)
            break
          case 'standard_yearly':
            if (
              account?.conditionalDisplayAttributes?.quarterlySubUpgradeNotification !== false ||
              account?.conditionalDisplayAttributes?.yearlySubUpgradeNotification !== false
            ) {
              const { data } = await updateConditionalDisplay(id, {
                yearlySubUpgradeNotification: false,
                quarterlySubUpgradeNotification: false,
              })
              setUser({
                ...response.data,
                account: data.account,
              })
              setGeoLocation(geoLocation)
            } else {
              setUser(response.data)
              setGeoLocation(geoLocation)
            }
            break
          default:
            setUser(response.data)
            setGeoLocation(geoLocation)
        }

        if (cache.has('getPlans') && !account?.active) {
          mutate('getPlans')
        }
      }
    },
  })

  return (
    <Form.Form isBusy={isBusy || isSuccess} className="w-full">
      {isError && <div className="py-3 font-light text-feedback-200">{message}</div>}
      {React.createElement(Styles.Input, {
        type: 'email',
        label: 'Email',
        value: email,
        onChange: (e) => setEmail(e.target.value),
        onSubmit: submit,
        error: errors?.['email'],
      })}
      {React.createElement(Styles.Input, {
        name: 'password',
        type: 'password',
        label: 'Password',
        value: password,
        onChange: (e) => setPassword(e.target.value),
        onSubmit: submit,
        error: errors?.['password'],
      })}
      <div className="mb-3 flex justify-end">
        <Link to='/forgot-password' className="!text-neutral-700 underline">
          Forgot password?
        </Link>
      </div>
      {React.createElement(Form.Submit, {
        isBusy,
        label: 'Log in',
        onClick: submit,
      })}
    </Form.Form>
  )
}

const Styles = {
  Input: styled(Input)`
    margin-bottom: 15px;
  `,
}
