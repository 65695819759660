import { api } from 'src/api'
import type { UserType } from 'src/types/User'

export const endSession = () => api.clearSessionState()

export const startSession = async ({ email, password }: { email: string; password: string }) => {
  const anonymousId = obe.analytics.anonymousId()

  const response = await api.client.post<UserType>('/users/sign_in.json', {
    api_version: 'v3',
    ...(anonymousId && { segment_anonymous_id: anonymousId }),
    user: {
      email,
      password,
    },
  })

  api.sessionState = {
    authorization: response.headers.authorization,
    userId: response.data.id,
  }

  return response
}

export const startSessionViaGoogle = async ({ id_token }: { id_token: string }) => {
  const response = await api.client.post<UserType>('/api/v4/sessions/oauths/google', {
    google: {
      id_token,
    },
  })

  api.sessionState = {
    authorization: response.headers.authorization,
    userId: response.data.id,
  }

  return response
}

export const startSessionViaApple = async ({
  id_token,
  access_token,
  first_name,
  last_name,
}: {
  id_token: string
  access_token: string
  first_name?: string
  last_name?: string
}) => {
  const response = await api.client.post<UserType>('/api/v4/sessions/oauths/apple', {
    apple: {
      id_token,
      access_token,
      first_name,
      last_name,
    },
  })

  api.sessionState = {
    authorization: response.headers.authorization,
    userId: response.data.id,
  }

  return response
}
